.asset-row-wrapper {
  width: 100%;
  height: auto;
  min-height: 55px;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 1px 10px rgba(59, 58, 67, 0.08);
  margin-bottom: 10px;
  padding: 0px 1em;
  padding-top: 15px;
  flex-direction: column;
  align-items: center;
  transition: all ease 0.3s;
}

.asset-row-wrapper:hover {
  box-shadow: 0px 1px 20px rgba(59, 58, 67, 0.2);
}

.asset-row-header {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.asset-row-header .asset-row-icons {
  display: flex;
  align-items: center;
  justify-content: center;
}
.asset-row-header .asset-row-icons img {
  width: 24px;
  height: 24px;
  border-radius: 100%;
}
.asset-row-header .asset-row-icons img:last-child {
  transform: translateX(-15px);
}
.asset-row-header .asset-row-description {
  font-size: 16px;
  margin-right: 15px;
  font-weight: 500;
}
.asset-row-header .asset-price {
  color: var(--manatee);
  font-weight: normal;
}
.asset-row-header .asset-row-pool-count {
  background: rgba(89, 59, 255, 0.3);
  border-radius: 7px;
  padding: 3px 11px;
  font-size: 11px;
  color: var(--iris);
  display: flex;
  justify-content: center;
  font-weight: 500;
  position: absolute;
  width: 64px;
  white-space: nowrap;
  right: 20%;
}

.asset-row-pool-count .MuiSkeleton-root {
  width: 100%;
}

.asset-row-header .asset-row-toggle {
  color: var(--iris);
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  font-size: 13px;
}
.asset-row-header .asset-row-toggle svg {
  width: 20px;
  height: 20px;
  margin-left: 0.5em;
}

.asset-row-wrapper .pool-rows-container {
  width: 100%;
  background: white;
  height: 0;
  padding: 0;
  overflow-y: hidden;
}

.asset-row-wrapper .pool-rows-container.showPools {
  height: auto;
  padding-bottom: 20px;
  padding-top: 24px;
  overflow-y: visible;
}

.pool-rows-header-container {
  display: flex;
  justify-content: space-between;
  padding: 0 11px;
  margin-bottom: 11px;
}
.pool-rows-header-container .pool-column-header {
  font-size: 13px;
  cursor: pointer;
  color: var(--manatee);
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  text-align: left;
}
.pool-rows-header-container .pool-column-header span {
  margin-right: 5px;
}
.pool-rows-header-container .pool-column-header svg {
  width: 10px;
  height: 10px;
  fill: var(--manatee);
}

@media screen and (max-width: 600px) {
  .asset-row-description {
    transform: translateX(-10px);
    font-size: 14px !important;
  }
  .asset-row-toggle-text {
    display: none;
  }
  .asset-row-pool-count {
    right: 6% !important;
  }
}


@media screen and (max-width: 475px) {
  .asset-row-pool-count {
    display: none !important;
  }
}