.lend-ratio-warning-wrapper {
  width: 500px;
  height: auto;
  padding: 20px;
  background-color: var(--white-smoke);
  border-radius: 10px;
  outline: none;
}

.lend-ratio-warning-wrapper input {
  margin-right: 5px;
  cursor: pointer;
}

.lend-ratio-warning-wrapper .MuiAlert-root {
  border-radius: 7px;
}

.warning-toggle-wrapper {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.warning-toggle-wrapper span {
  cursor: pointer;
}