.pool-filters-filter-wrapper {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.pool-filters-filter-wrapper.balance-button {
  width: 153px;
}

.pool-filters-filter-wrapper.expired-pool-filter {
  min-width: 150px;
}

.top-level-pool-filters {
  display: flex;
}

.pool-filters-filter-wrapper {
  display: flex;
  justify-content: center;
  padding-left: 8px;
  padding-right: 3px;
  align-items: center;
  background: white;
  height: 35px;
  border-radius: 10px;
  width: auto;
  padding: 0 1em;
  color: var(--manatee);
  cursor: pointer;
  font-size: 13px;
  position: relative;
  box-shadow: 0px 1px 10px rgba(59, 58, 67, 0.1);
  transition: all ease 0.3s;
  white-space: nowrap;
}


.pool-filters-mobile-wrapper .dropdown-wrapper .pool-filters-filter-wrapper { 
  background: none;
  box-shadow: none;
  color: black;
  justify-content: flex-start;
  margin-bottom: 5px;
}

.pool-filters-mobile-wrapper .dropdown-wrapper {
  top: 110%;
  left: 0;
  right: auto;
  width: auto;
}

.pool-filters-mobile-wrapper svg {
  stroke: var(--iris);
}

.pool-filters-mobile-wrapper {
  background: white;
  height: 35px;
  width: 35px;
  border-radius: 10px;
  padding: 0px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 10px rgba(59, 58, 67, 0.1);
  position: relative;
  z-index: 11;
}

.pool-filters-filter-wrapper:hover {
  box-shadow: 0px 0px 10px rgba(59, 58, 67, 0.2);
}

.pool-filters-filter-wrapper.bounce:hover {
  transform: scale(1.05);
}

.pool-filters-filter-wrapper.bounce:active {
  transform: translateY(1px);
}

.pool-filters-filter-wrapper .filter-dropdown-toggle svg {
  width: 20px;
  height: 20px;
}

.pool-filters-search-wrapper {
  display: flex;
  height: 40px;
  background: white;
  border-radius: 10px;
  max-width: 230px;
  width: 70%;
  height: 35px;
  box-shadow: 0px 1px 10px rgba(59, 58, 67, 0.1);
}

.pool-filters-search-wrapper input {
  display: block;
  width: 90%;
  height: 35px;
  border: none;
  color: black;
  background: none;
  padding-left: 1em;
  z-index: 1;
  outline: none;
  transition: all ease 0.2s;
}

.pool-filters-search-wrapper .search-toggle {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  z-index: 2;
}

.pool-filters-search-wrapper .search-toggle svg {
  transform: translateY(-2px);
  color: var(--iris);
}

.pool-filters-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}


@media screen and (max-width: 600px) {
  .pool-filters-search-wrapper input {
    font-size: 12pt;
  }
}