.pool-row-wrapper {
  width: 100%;
  height: 45px;
  display: flex;
  position: relative;
  border: 1px solid white;
  border-top: 1px solid var(--white-smoke);
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  transition: all ease 0.2s;
  padding: 0 11px;
  border-radius: 10px;
  outline: none;
}

.pool-row-wrapper:hover {
  background: var(--white-smoke);
}

.pool-row-wrapper.selected {
  background: var(--magnolia);
  border: 1px solid var(--pale-lavender);
}

.pool-row-column {
  font-size: 14px;
  color: var(--dark-jungle-green);
}

.pool-row-column:not(:last-child) {
  white-space: nowrap;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.pool-row-wrapper .available-token-symbol {
  display: inline;
  margin-left: 5px;
}

.asset-row-wrapper .available-token-symbol {
  display: none;
}

.my-pools-wrapper .available-token-symbol {
  display: inline !important;
}

.pool-row-column-label {
  position: relative;
  transition: all ease 0.3s;
  display: flex;
  align-items: center;
}

.pool-row-column-label.mobile-expiry-time {
  color: var(--manatee)
}

.pool-row-column-label img {
  margin-right: 4px;  
  width: 24px;
  height: 24px;
}

.pool-row-column-label.expired {
  color: var(--cherry);
}

.pool-row-column-label.near-expiry {
  animation: alert 2s ease infinite alternate;
}

@keyframes alert {
  from {
    color: var(--cherry);
  }
  to {
    color: var(--dark-jungle-green);
  }
}

.pool-row-column-label .loading-skeleton {
  display: block;
  min-width: 70px;
  max-width: 70px;
  max-height: 30px !important;
}

.pair-header, .pair-column {
  min-width: 30px;
  max-width: 30px;
}

.ltv-header, .ltv-column {
  min-width: 64px;
  max-width: 64px;
}

.ltv-header.my-pools-row, .ltv-column.my-pools-row {
  transform: translateX(5px) !important;
}

.term-rate-header, .term-rate-column {
  min-width: 40px;
  max-width: 40px;
}

.apr-header, .apr-column {
  min-width: 48px;
  max-width: 48px;
  transform: translateX(10px);
}

.expiry-header, .expiry-column {
  min-width: 147px;
  max-width: 147px;
  transform: translateX(10px);
}

.option-header {
  width: 24px;
}

.available-header, .borrowed-header, .available-column, .borrowed-column {
  min-width: 50px;
  max-width: 50px;
  transform: translateX(10px);
}

.option-column {
  max-width: 24px;
  color: var(--iris);
  padding-top: 3px;
  transition: all ease 0.3s;
  display: block !important;
  cursor: pointer;
}

.option-column.my-pools-row, .option-header.my-pools-row {
  transform: translateX(0px) !important;
}

.my-pools-left-section .pool-column-header:not(:first-child) {
  transform: translateX(-15px);
}

.term-rate-header.my-pools-row,
.term-rate-column.my-pools-row {
  transform: translateX(-15px) !important;
}

.expiry-header.my-pools-row {
  transform: translateX(-5px) !important;
}

.expiry-column.my-pools-row {
  transform: translateX(-10px) !important;
}

.available-header.my-pools-row, 
.available-column.my-pools-row {
  transform: translateX(0px) !important;
}

.borrowed-header.my-pools-row,
.borrowed-column.my-pools-row {
  transform: translateX(10px) !important;
}

.outer-dropdown-wrapper {
  width: auto;
  height: auto;
}

.dropdown-wrapper {
  width: 182px;
}

.dropdown-wrapper .dropdown-item {
  height: 29px;
}

.dropdown-wrapper .dropdown-item:hover {
  background: var(--magnolia);
}

.ltv-column .pool-row-column-label {
  padding: 3px 15px;
  border-radius: 11px;
  font-size: 11px;
  font-weight: 500;
  min-width: 64px;
  max-width: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ltv-column .pool-row-column-label.high {
  background: rgba(43, 153, 67, 0.3);
  color: var(--sea-green);
}

.ltv-column .pool-row-column-label.mid {
  background: rgba(242, 147, 57, 0.3);
  color: var(--deep-saffron);
}

.ltv-column .pool-row-column-label.low {
  background: rgba(209, 26, 42, 0.3);
  color: var(--fire-engine-red);
}

.ltv-column.no-fill {
  background: none !important;
}

.pool-row-wrapper .icon-pair {
  display: flex;
  align-items: center;
}

.pool-row-wrapper .icon-pair span {
  transform: translateX(-3px);
}

.pool-row-wrapper .icon-pair .lend-icon {
  transform: translateX(-12px);
}

.pool-row-wrapper .icon-pair {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.pool-row-wrapper .icon-pair span {
  font-size: 14px;
}

.pool-row-wrapper .icon-pair img {
  width: 24px;
  height: 24px;
}

.pool-row-wrapper .icon-pair img:last-child {
  transform: translateX(-10px);
}

.option-column {
  color: var(--iris);
  padding-top: 3px;
  transition: all ease 0.3s;
}

.expiry-calendar {
  min-width: 14px;
  max-height: 14px;
  margin-right: 5px;
  stroke: var(--iris);
  outline: none;
}

@media screen and (max-width: 600px) {
  .term-rate-header, .term-rate-column {
    display: none !important;
  }
  .borrowed-column, .borrowed-header {
    display: none !important;
  }
  .expiry-header, .expiry-column {
    min-width: 100px;
    max-width: 100px;
    transform: translateX(20px);
  }
  .ltv-header.my-pools-row, 
  .ltv-column.my-pools-row {
    transform: translate(15px) !important;
  }
  .expiry-header.my-pools-row,
  .expiry-column.my-pools-row {
    transform: translateX(15px) !important;
  }
  .available-header.my-pools-row, 
  .available-column.my-pools-row {
    transform: translate(0px) !important;
  }
  .apr-header, 
  .apr-column {
    transform: translateX(6px);
  }
  .pool-row-column .MuiSkeleton-root {
    max-width: 50px;
    min-width: 50px;
  }
  .option-column {
    transform: translateX(8px);
  }
}