.token-modal-container {
  width: 400px;
  min-height: 480px;
  background-color: white;
  padding: 2em 1em;
  border-radius: 10px;
  box-shadow: 0px 0px 11px 0px #000000;
  outline: none;
}

.token-modal-container .token-modal-header {
  color: var(--dark-jungle-green);
  font-size: 25px;
  font-family: 'Poppins', sans-serif;
}

.token-modal-container input {
  width: 100%;
  background: var(--white-smoke);
  border: 1px solid var(--pale-lavender);
  border-radius: 10px;
  height: 45px;
  color: var(--dark-jungle-green);
  padding-left: 1em;
  margin: 1em 0;
  font-size: 1em;
}

.tokens-container {
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
}

.tokens-container .token-container {
  width: 100%;
  height: 50px;
  cursor: pointer;
  background-color: white;
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 0 1em;
  margin: 0.5em 0;
  transition: all ease 0.3s;
}

.tokens-container .token-container img {
  width: 40px;
  height: 40px;
  padding: 1px;
  border-radius: 100%;
  margin-right: 10px;
}

.tokens-container .token-container:not(.disabled):hover {
  background: var(--white-smoke);
  padding-left: 1.5em;
}

.tokens-container .token-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: var(--dark-jungle-green);
}


.token-container.disabled {
  cursor: not-allowed;
}

.token-container.disabled .token-info {
  color: rgb(71, 71, 71);
}

.token-container.disabled img{
  filter: brightness(30%);
}