.liquidity-range-filter-wrapper {
  position: relative;
  width: 142px;
  display: flex;
  justify-content: flex-start;
}

.liquidity-range-filter-dropdown {
  display: flex;
  flex-direction: column;
  z-index: 100;
  background: white;
  border-radius: 7px;
  padding: 15px 18px;
  box-shadow: 0px 1px 10px rgba(59, 58, 67, 0.2);
}

.liquidity-range-filter-wrapper .dropdown-wrapper {
  padding: 0;
  width: 200px;
  left: 0;
  top: 120%;
}

.liquidity-range-filter-wrapper svg {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 15px;
}


.liquidity-range-filter-dropdown span {
  display: block;
  margin-bottom: 4px;
}

.liquidity-range-filter-dropdown input {
  border-radius: 7px;  
  border: none;
  background: var(--input);
  height: 30px;
  padding-left: 17px;
  outline-color: var(--iris);
  padding-top: 2px;
  width: 100%;
}

.liquidity-range-filter-dropdown button {
  background: var(--purple-gradient);
  border-radius: 7px;
  border: none;
  color: white;
  height: 30px;
  cursor: pointer;
  transition: all ease 0.3s;
  margin-top: 5px;
}

.liquidity-range-filter-dropdown button:hover{
  filter: brightness(90%);
}

.dropdown-input-wrapper {
  margin-bottom: 10px;
  position: relative;
}

.dropdown-input-symbol {
  position: absolute;
  top: 50%;
  left: 6px;
  transform: translateY(3px);
}


@media screen and (max-width: 600px) {
  .dropdown-wrapper {
    z-index: 10;
    overflow: visible;
  }
  .dropdown-input-wrapper input {
    font-size: 12pt;
  }
  .liquidity-range-filter-dropdown {
    max-width: 172px;
  }
  .liquidity-range-filter-dropdown div, .liquidity-range-filter-dropdown input{
    max-width: 100%;
  }
}