.rollover-row {
  position: relative;
  display: flex;
  width: 100%;
  height: 45px;
  border-radius: 10px;
  align-items: center;
  justify-content: space-around;
  margin: 5px 0;
  transition: all ease 0.3s;
  cursor: pointer;
  font-size: 12px;
  background: var(--magnolia);
  border: solid 1px var(--pale-lavender);
}

.rollover-row.disabled:after{
  content: "";
  width: 95%;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--auro-metal-saurus);
}

.rollover-row:hover {
  border: solid 1px var(--iris);
}

.rollover-row.active {
  border: solid 1px var(--iris);
}

.rollover-row div {
  width: 200px;
  display: flex;
  justify-content: center;
}

.rollover-icon-pair {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rollover-chevron-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60px;
}

.rollover-row-red {
  color: #F85062;
}

.rollover-pair {
  margin-left: 5px;
}

.rollover-text {
  font-size: 14px;
  color: var(--dark-jungle-green);
}

.scroll-view{
  width: 100%;
  height: 80%;
  display: block;
  align-items: center;
  margin-top: 5px;  
  overflow-x: hidden;
  overflow-y: auto;
}

.token-logo {
  width: 25px;
  height: 25px;
  border-radius: 100%;
}