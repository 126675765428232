.topnav-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 29px;
  margin-top: 23px;
  z-index: 10;
}

.version-message-wrapper {
  display: flex;
  justify-content: space-between;
}

.social-logos {
  display: flex;
  justify-content: space-around;
  position: absolute;
  width: 100%;
  bottom: 0;
  margin-bottom: 2em;
}

.social-logos a {
  width: 20px;
  height: 20px;
  margin: 0 10px;
  transition: all ease 0.3s;
  cursor: pointer;
}

.social-logos a:hover {
  transform: scale(1.5);
}

.social-logos a img {
  width: 100%;
}

.topnav-header.mobile {
  display: none;
  position: relative;
}

.topnav-header {
  color: var(--lavender-indigo);
  font-weight: thin;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  transition: all ease 0.3s;
  font-size: 17px;
  cursor: pointer;
}

.version-message-wrapper {
  background: var(--iris);
  color: white;
  display: flex;
  min-height: 45px;
  justify-content: space-between;
  padding: 0 1em;
  align-items: center;
  border-radius: 10px;
  margin-top: 15px;
}

.version-message-wrapper a {
  text-decoration: underline;
  cursor: pointer;
}

.version-message {
  display: flex;
  align-items: center;
}

.version-message a {
  margin: 0 5px;
}

.version-message svg {
  margin-right: 10px;
  width: 20px;
}

.wrong-network-message {
  background: #f2286d;
  color: white;
  display: flex;
  min-height: 45px;
  justify-content: space-between;
  padding: 0 1em;
  align-items: center;
  border-radius: 10px;
  margin-top: 15px;
  cursor: pointer;
}

.wrong-network-message-right {
  display: flex;
  align-items: center;
}

.wrong-network-message svg {
  margin-right: 10px;
  width: 20px;
}

.wrong-network-message button {
  color: white;
  background: none;
  border: 1px solid white;
  border-radius: 7px;
  cursor: pointer;
  padding: 5px 10px;
  font-weight: bold;
}

.topnav-header .vendor-logo {
  width: 27px;
  height: 23px;
  margin-right: 37px;
  transition: all ease 0.5s;
}

.topnav-header .vendor-logo:hover {
  transform: scale(1.1);
}

.topnav-header:hover span {
  position: relative;
  overflow: hidden;
}

.topnav-button {
  width: 144px;
  height: 35px;
  padding: 0 10px;
  border-radius: 10px;
  color: #AEAFB7;
  background-color: var(--dark-purple);
  display: flex;
  align-items: center;
  transition: all ease 0.3s;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  border: none;
  margin-left: 10px;
  box-shadow: 0px 1px 10px rgba(59, 58, 67, 0.1);
  justify-content: center;
}

.topnav-button:hover {
  background-color: var(--white-smoke);
}

.wallet-connect-button {
  color: var(--iris);
  font-size: 13px;
  font-weight: 500;
}

.topnav-buttons {
  display: flex;
  position: relative;
  justify-content: flex-end;
  flex: 1;
}

.topnav-button.filled {
  background-color: rgba(73, 49, 207, 0.3);
  border-color: var(--mid-purple);
}

.topnav-button span {
  display: flex;
  justify-content: center;
  align-items: center;
}

.options-button {
  width: 38px;
  height: 35px;
  padding: 0;
  display: flex;
  justify-content: center;
  color: var(--iris);
  background: white;
  position: relative;
}

.options-button:hover {
  transform: scale(1.05);
}

.options-button:active {
  transform: scale(1);
}

.wallet-connect-button div {
  width: 20px;
  height: 20px;
  margin-left: 1em;
}

.network-connect-button {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  background-color: white;
  color: var(--dark-jungle-green);
  position: relative;
}

.network-connect-button svg {
  width: 20px;
}

.network-connect-button span {
  margin-right: 5px;
}

.network-connect-button span svg {
  width: 15px;
  margin-right: 6px;
}

.network-connect-button img {
  margin-right: 0.7em;
  width: 20px;
  height: 20px;
}

.nav-link-container {
  display: flex;
}

.nav-links {
  height: auto;
}

.nav-link-container .nav-link {
  margin-right: 43px;
  display: flex;
  align-items: center;
  color: var(--iris);
  transition: all ease 0.3s;
  margin-right: 2em;
}

.nav-link-container .nav-link:hover {
  transform: scale(1.05);
}

.nav-link {
  font-size: 16px;
}

.nav-link svg {
  margin-right: 1em;
  width: 15px;
  height: 15px;
}

.nav-link.active {
  font-weight: bold;
}

.beta-text {
  font-size: 10px;
  position: absolute;
  left: 16px;
  bottom: 7px;
  transform: rotate(-61deg);
}

.mobile-menu-button {
  display: none;
  position: relative;
}

.tvl-wrapper {
  font-size: 13px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  background: white;
  border-radius: 10px;
  height: 35px;
  padding: 0 15px;
  box-shadow: 0px 1px 10px rgba(59, 58, 67, 0.1);
}

.tvl-wrapper .MuiSkeleton-root {
  min-width: 35px;
  margin-left: 3px;
}

@-webkit-keyframes colorful {
  from {
    color: #8d4ad4;
  }
  to {
    color: #ff5777;
  }
}

@keyframes colorful {
  from {
    color: #8d4ad4;
  }
  to {
    color: #ff5777;
  }
}

@keyframes slides {
  0% {
    left: -100%;
  }
  100% {
    left: 110%;
  }
}
@media screen and (max-width: 1180px) {
  .mobile-menu-button {
    display: block;
  }
  .topnav-header.mobile {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    margin-bottom: 22px;
    margin-top: 0px;
    position: relative;
  }
  .topnav-header.desktop, .topnav-wrapper.desktop {
    display: none;
  }
  .topnav-buttons {
    flex-direction: column;
    align-items: flex-end;
    display: none;
  }
  .topnav-buttons .topnav-button {
    margin-bottom: 15px;
  }
  .beta-text {
    display: none;
  }
}
