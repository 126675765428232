.network-select-wrapper {
  width: 145px;
  height: auto;
  position: absolute;
  top: 120%;
  left: 0px;
  background: var(--white-smoke);
  padding: 15px 13px;
  box-shadow: 0px 0px 10px rgba(59, 58, 67, 0.2);
  border-radius: 10px;
  transition: all ease 0.3s;
}

.network-select-option {
  display: flex;
  align-items: center;
  color: var(--dark-jungle-green);
  width: 100%;
  transition: all ease 0.3s;
  background: none;
  border: none;
  cursor: pointer;
}

.network-select-option img {
  width: 20px;
  margin-right: 15px;
}

.network-select-option:not(:first-child) {
  margin-top: 15px;
}

.network-select-option:hover {
  transform: translateX(3px);
}