:root {
  --blue-accent: #3B6AFC;
  --mid-purple: #350F6B;
  --bright-purple: #8646CB;
  --deep-fuchsia: #C551A7;
  --dark-pastel-purple: #9e72cd;
  --midnight-blue: #270e4c;
  --onyx: #06071d;
  --purple-pizzazz: #FC56D2;
  --lavender-indigo: #8d4ad4;
  --ash-grey: #aeafb7;
  --red-accent: #D1495B;
  --almost-white: #FEFFFE;
  --almost-black: #260950;
  --secondary-text: #373737;
  --border-color: #9F9F9F;
  --input: #F2F4F8;
  --colorful-gradient: linear-gradient(90deg, #8d4ad4 0%, #ff5777 100%);
  --purple-gradient: linear-gradient(38deg, #4753d8 0%, #954ef7 100%);
  --blue-gradient: linear-gradient(63deg, #4753d8 0%, #804fef 100%);
  --red-gradient: linear-gradient(32deg, #fa756d 0%, #f2286d 100%);
  /* new ui colors*/
  --iris: #4931cf;
  --dark-jungle-green: #131523;
  --han-purple: #593bff;
  --sea-green: #2b9943;
  --deep-saffron: #f29339;
  --fire-engine-red: #d11a2a;
  --magnolia: #f7f5ff;
  --ultramarine-blue: #605bff;
  --manatee: #9ea1ac;
  --white-smoke: #f5f6fa;
  --pale-lavender: #ddd7ff;
  --glitter: #e7e9ef;
  --silver: #b6b8c3;
  --gainsboro: #d9dbe9;
  --seashell: #fcf3f1;
  --cherry: #dd2c68;
  --lavender-rose: #ff98f1;
  --davy-grey: #50525c;
  /* dark mode */
  --majorelle-blue: #764ce1;
  --bright-lavender: #a988ff;
  --auro-metal-saurus: #717585;
}



.mid-separator {
  background-color:rgba(89, 59, 255, 0.3);
  border-radius: 100px;
  min-width: 33px;
  min-height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--iris);
  transform: translateY(10px);
}

.MuiAlert-root {
  background: var(--magnolia) !important;
  border: 1px solid var(--pale-lavender);
  color: var(--dark-jungle-green);
  font-size: 1em;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  background: var(--white-smoke);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Poppins", sans-serif;
  overflow-y: scroll;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}


.modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal div[aria-hidden=true] {
  background-color: rgba(0, 0, 0, 0.8) !important;
  animation: fadeInOpacityOnly ease 0.5s;
}

.disable-select {
  user-select: none;
}

.fade-in {
  animation: fadeIn ease 0.5s;
}

.fade-in-longer {
  animation: fadeIn ease 1s;
}



@media screen and (min-width: 700px) {
  /* class for left-side wrapper across the site */
  .left-section {
    min-width: 756px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeInOpacityOnly {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media screen and (max-width: 600px) {
  html {
    min-width: 100vw;
  }
}