.pool-simulation-wrapper {
  padding: 15px;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 1px 10px rgba(59, 58, 67, 0.1);
  font-size: 10px;
  height: 100%;
  margin-bottom: 29px;
  position: relative;
  max-height: 220px;
}

.pool-simulation-wrapper .legend-item-2 {
  display: none !important;
}

.pool-simulation-wrapper .no-data-message {
  position: absolute;
  top: 55%;
  left: 50%;
  font-size: 12px;
  transform: translateX(-50%); 
  color: var(--auro-metal-saurus);
  z-index: 10;
}

.pool-simulation-title {
  font-size: 16px;
  font-weight: 500;
  position: relative;
}

.pool-simulation-title > span {
  position: relative;
}

.simulation-loader {
  position: absolute !important;
  left: calc(100% + 5px);
  top: 1px;
}

.recharts-legend-wrapper {
  text-align: right !important;
  transform: translateX(10px);
}

.recharts-default-legend {
  text-align: right !important;
  font-size: 10px;
}

.recharts-legend-item-text {
  color: var(--davy-grey) !important;
  transform: translateY(-1px);
}

.recharts-default-legend {
  display: flex;
  justify-content: flex-end;
}

.recharts-legend-item {
  margin-bottom: 0 !important;
  display: flex !important;
}

.recharts-legend-item svg {
  filter: drop-shadow(0 2px 4px rgba(31, 33, 65, 0.16));
}

.recharts-surface {
  border-radius: 3px;
}

.recharts-symbols {
  filter: drop-shadow(0px 2px 5px rgba(73, 49, 207, 0.4));
}

.recharts-cartesian-axis-tick text {
  color: var(--onyx);
  font-size: 12px;
}

.recharts-tooltip-wrapper {
  border-radius: 5px;
  background: var(--magnolia);
  outline: var(--pale-lavender);
  border: 1px solid var(--pale-lavender);
  box-shadow: 0px 1px 10px rgba(59, 58, 67, 0.08);
  font-family: "Poppins", sans-serif;
}

.graph-tooltip {
  padding: 0px 10px;
}

.recharts-surface .recharts-layer path:nth-child(2) {
  filter: drop-shadow(0px 2px 5px rgba(73, 49, 207, 0.4));
}

@media screen and (max-width: 1183px) {
  .recharts-default-legend {
    text-align: left !important;
  }
  .my-pools-left-graph .recharts-legend-wrapper {
    top: -4px !important;
    left: -8px !important;
  }
  .create-pool-wrapper .recharts-legend-wrapper {
    top: -1px !important;
    left: 3px !important;
  }
  .my-pools-left-graph .recharts-responsive-container {
    margin-top: 10px;
  }
  .create-pool-wrapper .recharts-responsive-container {
    margin-top: 5px;
  }
  .create-pool-wrapper .pool-simulation-wrapper {
    margin-top: 0px;
    margin-bottom: 10px;
  }

  .recharts-default-legend {
    justify-content: flex-start;
  }
}