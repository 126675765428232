html, body, #root, .App, #app, #app > div {
  height: 100%;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--white-smoke);
}

.top-level-button {
  position: absolute;
  top: 10px;
  height: 40px;
  width: auto;
  padding: 0 1em;
  border-radius: 10px;
  color: #AEAFB7;
  background-color: #270E4C;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all ease 0.3s;
  cursor: pointer;
}

a, a:hover, a:visited, a:active {
  outline: none;
  color: inherit;
  text-decoration: none;
}

.create-pool-button {
  left: 10px;
}

.top-level-button:hover {
  filter: brightness(80%);
}

.back-blob {
  width: 750px;
  height: 750px;
  position: absolute;
  z-index: 0;
}

.view-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 17px;
  height: auto;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 1px 10px rgba(59, 58, 67, 0.08);
}

.flexbox-container {
  display: flex;
  flex-direction: column;
  padding: 1em 0;
  border-radius: 10px;
  background: rgba(0,0,0,0);
  align-items: center;
  justify-content: space-evenly;
  transition: all ease 0.3s;
}

.flexbox-container.no-padding {
  padding: 0;
}

.token-input-container {
  width: 100%;
  height: 150px;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.blob-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
}

.info-section-container {
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.info-section-container.block {
  display: block;
}

.info-section-container.margin-bottom {
  margin-bottom: 1.5em;
}

.bring-front {
  z-index: 2;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.main-container-shadow {
  box-shadow: 0px 1px 10px rgb(59 58 67 / 8%);
}

.no-padding {
  padding: 0;
}

.__react_component_tooltip {
  background-color: var(--iris) !important;
  max-width: 400px;
  text-align: left;
}

.__react_component_tooltip::after {
  border-top-color: var(--iris) !important;
}

.tool-tip {
  font-size: 11pt !important;
  color: white !important;
}