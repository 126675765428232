.dashboard-wrapper {
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  max-width: 1233px;
}

.dashboard-content {
  display: flex;
  flex: 1;
  position: relative;
}

.routes-container {
  z-index: 2;
  width: 100%;
  height: auto;
  overflow-y: auto;
  overflow: visible;
}

@supports (-webkit-touch-callout: none) {
  .dashboard-wrapper {
    padding-top: 50px !important;
  }
}

@media screen and (max-width: 1180px) {
  .dashboard-wrapper {
    max-width: 738px;
  }
}


@media screen and (max-width: 738px) {
  .dashboard-wrapper {
    width: 95%;
  }
}