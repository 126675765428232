.disclaimer-wrapper {
  width: 600px;
  height: auto;
  color: var(--dark-jungle-green);
  padding: 1em;
  outline: none;
  display: flex;
  flex-direction: column;
  background-color: var(--magnolia);
  border-radius: 10px;
  border: 1px solid var(--pale-lavender);
  box-shadow: 0px 0px 11px 0px #000000;
  position: relative;
}

.disclaimer-wrapper svg {
  position: absolute;
  right: 22px;
  top: 21px;
  cursor: pointer;
  transition: all ease 0.3s;
  color: var(--cherry);
}


.disclaimer-wrapper svg:hover {
  transform: rotate(180deg);
}

.disclaimer-wrapper .disclaimer-header {
  flex: 1;
  font-size: 15px;
}

.disclaimer-wrapper ul {
  border-radius: 10px;
  font-size: 13px;
  background-color: var(--magnolia);
}

.disclaimer-wrapper .disclaimer-buttons {
  display: flex;
  flex-direction: column;
}
.disclaimer-wrapper .disclaimer-buttons button {
  width: 100%;
  height: 50px;
  margin: 5px 0;
  border: none;
  border-radius: 10px;
  transition: all ease 0.3s;
  cursor: pointer;
  font-weight: bold;
}
.disclaimer-wrapper .disclaimer-buttons button:hover {
  transform: scale(1.01);
}
.disclaimer-wrapper .disclaimer-buttons .agree-button {
  background: var(--purple-gradient);
  color: var(--white-smoke);
}

.disclaimer-wrapper .disclaimer-buttons .decline-button {
  background-color: rgba(0, 0, 0, 0);
  outline: 1px solid var(--pale-lavender);
  color: var(--dark-jungle-green);
}
.disclaimer-wrapper h1 {
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
}
.disclaimer-wrapper h3 {
  transform: translateX(-25px);
}
.disclaimer-wrapper ul {
  overflow-y: auto;
  display: block;
  max-height: 250px;
}
.disclaimer-wrapper ul li {
  margin-bottom: 10px;
}

.disclaimer-banner-wrapper {
  position: fixed;
  bottom: 20px;
  max-width: 1233px;
  height: auto;
  padding: 1em;
  background-color: var(--magnolia);
  border: 1px solid var(--pale-lavender);
  box-shadow: 0px 1px 10px rgba(59, 58, 67, 0.08);
  border-radius: 10px;
  display: flex;
  align-items: center;
  color: var(--dark-jungle-green);
  font-size: 11px;
  z-index: 10;
}


.disclaimer-banner-wrapper b {
  cursor: pointer;
}

.banner-button-wrapper {
  display: flex;
  justify-content: space-between;
  margin-left: 2em;
}

.banner-button-wrapper button {
  width: 100px;
  height: 30px;
  border-radius: 5px;
  border: none;
  background-color: var(--magnolia);
  border: 1px solid var(--pale-lavender);
  box-shadow: 0px 1px 10px rgba(59, 58, 67, 0.08);
  cursor: pointer;
  transition: all ease 0.3s;
}

.banner-button-wrapper button:first-child {
  margin-right: 10px;
}

.banner-button-wrapper button:hover {
  transform: scale(1.05);
}

.banner-button-wrapper button:active {
  transform: scale(1);
}

.banner-message b {
  text-decoration: underline;
}

@media screen and (max-width: 1180px) {
  .disclaimer-banner-wrapper {
    display: flex;
    flex-direction: column;
    width: 90%;
  }
  .banner-button-wrapper {
    width: 100%;
    justify-content: flex-start;
    margin-top: 10px;
    margin-left: 0;
  }
}