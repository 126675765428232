.repay-row {
  display: flex;
  width: 100%;
  height: 45px;
  background: var(--magnolia);
  border: solid 1px var(--pale-lavender);
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
}

.repay-row div {
  color: var(--dark-jungle-green);
}

.repay-row div {
  flex: 1;
  display: flex;
  justify-content: center;
}

.repay-row div.repay-expiry {
  flex: 2;
}

.repay-icon-pair {
  display: flex;
  justify-content: center;
  align-items: center;
}

.repay-chevron-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60px;
}

.repay-row-red {
  color: #F85062;
}

.repay-pair {
  margin-left: 5px;
}

.repay-text {
  color: #fff;
}

.repay-text .MuiSkeleton-root {
  width: 50%;
  height: 25px !important;
}