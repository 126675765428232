.transaction-review {
  margin-top: 1em;
  background-color: var(--white-smoke);
  width: 100%;
  height: auto;
  padding: 1em 0;
  overflow: visible;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 11px 0px #000000;
  border-radius: 10px;
  transition: all ease 0.5s;
  font-size: 14px;
}

.transaction-review.floating {
  max-width: 500px;
}

.transaction-review .transaction-info {
  width: 94%;
  margin: auto;
  margin-bottom: 20px !important;
}

.review-header {
  color: #fff;
  cursor: pointer;
  display: flex;
  flex: 1 1;
  font-size: 19px;
  justify-content: space-between;
  align-items: center;
}

.review-header .interest-notice svg {
  width: 13px;
  margin-left: 7px;
}

.review-header .interest-notice {
  color: var(--iris);
  font-size: 11px;
  display: flex;
  align-items: center;
}

.review-header.hide {
  display: none;
}

.transaction-review .transaction-info h1 {
  margin-top: 0;
  margin-bottom: 5px;
  color: white;
}

.transaction-review .transaction-info .info-item {
  display: flex;
  justify-content: space-between;
  margin: 0.2em 0;
}

.transaction-review .transaction-info .info-item span {
  color: white;
}

.transaction-review .transaction-info .info-item .info-left {
  color: var(--auro-metal-saurus);
}