.token-filter-wrapper {
  position: relative;
  width: 123px;
  justify-content: flex-start;
}

.token-filter-wrapper svg {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 15px;
}

.token-filter-token-wrapper:not(:last-child) {
  margin-bottom: 3px;
}

.no-tokens-found-message {
  display: flex;
  height: 40px;
  align-items: center;
  padding: 0 11px;
}

.no-tokens-found-message svg {
  position: relative;
  top: 0;
  right: 0;
  transform: translateY(0);
  margin-right: 6px;
  stroke: var(--iris);
}

.token-filter-token-wrapper {
  display: flex;
  justify-content: space-between;
  height: 40px;
  align-items: center;
  padding: 0 10px;
}

.token-filter-token-wrapper:hover {
  background: #f9fafb;
}

.token-filter-token-wrapper input[type="checkbox"] {
  width: 17px;
  height: 17px;
  border-radius: 4px;
  margin: 0;
  transition: all ease 0.2s;
  position:relative;
}

.token-filter-token-wrapper input[type='checkbox']:checked {
  border: 2px solid #8e6add;
}

.token-filter-token-wrapper input[type='checkbox']:checked:after {
  content: '';
  position: absolute;
  width: 1.2ex;
  height: 0.4ex;
  top: 48%;
  left: 50%;
  border: 2px solid #8e6add;
  border-top: none;
  border-right: none;
  transform: translate(-50%, -60%) rotate(-45deg);
}

.token-filter-token-wrapper input[type='checkbox']{
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  appearance:none;
  box-shadow: none;
  border: 2px solid #d0d5dd;
  font-size: 1em;
}

.token-filter-search {
  width: 100%;
}

.token-filter-token-left {
  display: flex;
  align-items: center;
}

.token-filter-token-wrapper img {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  margin: 0 9px;
}

.token-filter-token-price {
  color: var(--davy-grey)
}

.token-filter-wrapper .dropdown-wrapper {
  width: 235px;
  max-height: 300px;
  left: 0 !important;
  top: 120%;
  padding: 0;
  padding-top: 2px;
}

.token-filter-top-section {
  padding: 0 10px;
}

.token-filter-bottom-section {
  overflow-y: auto;
}

.token-filter-bottom-section::-webkit-scrollbar {
  width: 8px;
}

.token-filter-bottom-section::-webkit-scrollbar-track {
  background: #f9fafb;
}

.token-filter-bottom-section::-webkit-scrollbar-thumb {
  background: var(--silver);
  border-radius: 2px;
}


.token-filter-search-wrapper {
  margin-bottom: 10px;
  height: 35px;
  min-height: 35px;
  border-radius: 7px;
  outline: none;
  border: 1px solid var(--manatee);
  position: relative;
  margin-top: 10px;
}

.token-filter-search-icon {
  position: absolute;
  right: 9px !important;
  stroke: var(--manatee);
}


.token-filter-search {
  min-width: 100%;
  height: 100%;
  border: none;
  border-radius: 7px;
  border: none;
  padding-top: 0;
  padding-bottom: 0;
  outline: none;
  background: none;
  padding-left: 15px;
}

.token-filter-select-all {
  min-height: 35px;
  background-color: var(--pale-lavender);
  margin-bottom: 5px;
  border-radius: 7px;
  display: flex;
  justify-items: center;
  align-items: center;
  color: var(--iris);
  font-weight: 600;
  padding-left: 15px;
  cursor: pointer;
}

.supported-tokens-wrapper {
  overflow-y: auto;
}

.token-filter-wrapper .tab-container{
  margin: 0;
  width: 100%;
  margin-top: 10px;
}

.token-filter-wrapper .tab-item {
  font-size: 13px;
  font-weight: 500;
}

.token-filter-wrapper .tab-slider {
  width: 50%;
}

.token-filter-wrapper .tab-slider.borrow, .tab-slider.add, .tab-slider.repay {
  left: 50%;
}

.token-filter-wrapper .tab-slider.deposit {
  left: 0%;
}