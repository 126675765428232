.lending-pool-wrapper {
  width: 100%;
  height: 100%;
}

.info-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.info-container input {
  width: 185px;
}

.row-title {
  align-self: flex-start;
  justify-self: left;
  color: var(--manatee);
  font-size: 14px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.info-title-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 40px;
}

.pool-link {
  font-size: 13px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 3px;
  margin-right: 5px;
  color: var(--iris) !important;
}

.pool-link svg {
  width: 10px;
  margin-left: 5px;
  transform: translateY(-1px);
}

.input-price {
  opacity: 0.7;
  font-size: 12px;
}

.info-title {
  color: var(--manatee);
  margin-bottom: 5px;
  display: flex;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.info-title.expiry-title {
  min-width: 185px;
}

.info-title svg {
  width: 13.6px;
  margin-left: 7px;
  color: var(--iris);
}

.borrow-view {
  align-items: center;
  justify-content: space-evenly;
  height: auto;
  padding-top: 17px;
  padding-bottom: 17px;
}

.repay-view {
  align-items: center;
  justify-content: flex-start;
  height: 300px;
}

.repay-view.view-section {
  padding-top: 10px;
  padding-bottom: 10px;
}

.lending-pool-activity-wrapper {
  max-width: 487px;
  position: relative;
}

.lending-pool-activity-wrapper .tab-container {
  margin-top: 10px;
}

.repay-view .row-header span:nth-child(2) {
  flex: 2;
}

.rollover-view {
  align-items: center;
  justify-content: flex-start;
  height: 300px;
  position: relative;
}

.rollover-view.view-section {
  padding-top: 10px;
  padding-bottom: 10px;
}

.rollover-pool-notice {
  color: var(--dark-jungle-green);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.row-header {
  display: flex;
  width: 100%;
  margin: auto;
  justify-content: space-around;
  margin-top: 17px;
  margin-bottom: 0;
}

.row-header span {
  flex: 1;
  display: flex;
  justify-content: center;
  color: #9ea1ac;
  font-size: 0.9em;
}

.lending-pool-wrapper {
  height: 202px;
}

.lending-pool-wrapper .transaction-review {
  margin-top: 1em;
  background-color: white;
  height: auto;
  padding: 1em 0;
  overflow: visible;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 10px rgba(59, 58, 67, 0.08);
  border-radius: 10px;
  transition: all ease 0.5s;
}

.explore-right-section .flexbox-container {
  background: white;
}

.explore-right-section .pool-link {
  display: none;
}

.transaction-review .transaction-alert {
  background: var(--magnolia);
  border: 1px solid var(--pale-lavender);
  color: var(--dark-jungle-green);
  height: 60px;
  margin-top: 0.5em;
  font-size: 1em;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.transaction-review .transaction-info {
  width: 93%;
  margin: auto;
}

.transaction-review .transaction-info .transaction-info-text {
  margin-top: 7px;
}

.transaction-review .transaction-info .MuiPaper-root {
  margin-top: 18px;
}

.transaction-review .transaction-info h1 {
  margin-top: 0;
  margin-bottom: 5px;
  color: white;
}

.transaction-review .transaction-info .info-item {
  display: flex;
  justify-content: space-between;
  margin: 0.2em 0;
}

.transaction-review .transaction-info .info-item span {
  color: var(--dark-jungle-green);
}

.info-left svg {
  color: var(--iris);
  width: 15px;
  height: 15px;
  transform: translate(5px, 2px);
}

.token-selector-long-wrapper {
  width: 100%;
  margin-top: 3px;
}

.row-title-row {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin-top: 10px;
  padding-left: 9px;
  font-size: 14px;
}

.repay-title {
  color: #B8B8BA;
  margin-bottom: 5px;
}

.top-offset {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.tooltip-color {
  background: red;
}

.review-header {
  color: var(--dark-jungle-green);
  cursor: pointer;
  display: flex;
  flex: 1 1;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 7px;
}

.repay-title .row-header .rollover-pool-notice {
  font-size: 14px;
}

.lending-pool-close-modal-wrapper {
  color: var(--iris);
  z-index: 10;
  background-color: white;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  box-shadow: 0px 1px 10px rgba(59, 58, 67, 0.1);
  padding: 3px;
}

.lending-pool-close-modal {
  width: 20px;
  height: 20px;
}


@media screen and (max-width: 1180px) {
  .lending-pool-wrapper {
    height: auto;
  }
  .transaction-review {
    max-width: 487px;
  }
}

@media screen and (max-width: 600px) {
  .lending-pool-activity-wrapper, .transaction-review {
    max-width: 95% !important;
    min-width: 95% !important;
    transform: translateX(-2px);
  }
  .my-pools-right-section .lending-pool-activity-wrapper{
    padding-bottom: 20px;
  }
  .token-amount-entry {
    font-size: 12pt;
  }
  .repay-row, .row-header {
    display: none !important;
  }
  .repay-view {
    max-height: 220px;
    height: auto;
  }
  .pool-link {
    display: none;
  }
  .rollover-view {
    height: 200px;
  }
  .rollover-pool-notice {
    white-space: nowrap;
  }
  .mid-separator {
  }
  .info-title.expiry-title {
    min-width: 40%;
  }
  .info-title-container {
    margin-top: 35px;
  }
  .info-value.ltv-value,
  .info-title.ltv-title {
    transform: translateX(10px);
  }
  .info-value.term-fee-value,
  .info-title.term-fee-title {
    transform: translateX(15px);
  }
  .review-header {
    flex-direction: column;
    align-items: flex-start;
  }
}