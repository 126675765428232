.create-pool-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
}

.create-pool-wrapper .pool-filters-search-wrapper {
  display: none;
}

.create-pool-confirm-button {
  margin-bottom: 15px;
}

.create-pool-wrapper .action-button-container {
  width: 100%;
  margin-top: 15px;
}

.create-pool-wrapper .widget-wrapper {
  padding-top: 10px;
  padding-bottom: 19px;
}

.create-pool-wrapper .token-input-container {
  display: flex;
  flex-direction: row;
  height: auto;
}

.create-pool-wrapper .increment-selector-wrapper {
  height: 45px;
}

.create-pool-wrapper .increment-selector-wrapper .increment-selector-button {
  min-width: 45px;
  min-height: 45px;
}

.create-pool-wrapper .increment-selector-wrapper input {
  width: 45px;
  margin: 0 10px;
  width: 60px !important;
}

.create-pool-wrapper .inner-token-input-container:first-child {
  margin-right: 15px;
}

.create-pool-wrapper .info-title-container {
  margin-top: 15px;
}

.info-title.apr-title {
  justify-content: flex-end;
}

.create-pool-wrapper .info-section-container.block {
  margin-top: 15px;
}

.create-pool-wrapper .increment-selector-percent {
  top: 32% !important;
  right: 35% !important;
}

.create-pool-graph-container {
  width: 100%;
  height: 250px;
  width: 50px;
  flex: 4.4;
}

.create-pool-left {
  flex: 1.5;
  margin-right: 16px;
}

.create-pool-right {
  flex: 1;
  width: 440px;
}

.private-borrowers-wrapper {
  width: 99%;
  background: var(--white-smoke);
  border-radius: 10px;
  padding: 7px 1em;
  min-height: 45px;
  display: flex;
  flex-wrap: wrap;
  cursor: text;
  max-height: 150px;
  overflow-y: auto;
}

.private-borrowers-input {
  border-radius: 10px;
  width: 100%;
  border: none;
  background: var(--white-smoke);
  height: 30px;
  font-size: 14px;
  color: var(--dark-jungle-green);
  outline: none;
  white-space: nowrap;
  padding-left: 0;
  padding-right: 0;
}

.lend-ratio-selector {
  width: 99%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
}

.lend-ratio-selector .lend-ratio-option, .lend-ratio-selector input {
  width: 100px;
  height: 40px;
  border-radius: 10px;
  background: var(--pale-lavender);
  border: none;
  color: var(--iris);
  transition: all ease 0.3s;
  font-family: "Poppins", sans-serif;
}

.lend-ratio-selector button {
  flex: 2;
  margin-right: 1em;
  cursor: pointer;
}

.lend-ratio-selector button.active {
  background: var(--purple-gradient);
  border: none;
  color: white;
}

.lend-ratio-selector button:hover {
  filter: brightness(80%);
}

.lend-ratio-selector input {
  flex: 1;
  padding-left: 1em;
  max-width: 40%;
}

.token-dropdown {
  margin-top: 5px;
}

.row-title {
  font-size: 14px;
}

.info-title {
  min-width: 100px;
}

.info-title.lend-ratio-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transform: translateY(4px);
}

.info-title.lend-ratio-title span {
  display: flex;
  align-items: center;
}

.info-item {
  font-size: 14px;
}

.toggles-container {
  width: 100%;
  margin-top: 10px;
}

.toggles-container .toggle-wrapper {
  margin: 0.6em 0;
}

.toggle-wrapper {
  text-align: right;
  display: flex;
  align-items: center;
  color: #B8B8BA;
  align-items: flex-end;
}

.toggle-wrapper .toggle-wrapper-label {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
  color: var(--iris);
}

.toggle-wrapper .toggle-wrapper-switch {
  width: 44px;
  height: 22px;
  margin-top: 5px;
  margin-right: 10px;
  background: var(--silver);
  border-radius: 20px;
  position: relative;
  cursor: pointer;
  transition: all ease 0.3s;
}

.toggle-wrapper .toggle-wrapper-switch.checked {
  background: var(--pale-lavender);
}

.toggle-wrapper .toggle-wrapper-switch .toggle-wrapper-slider {
  width: 16px;
  height: 16px;
  border-radius: 100%;
  position: absolute;
  background: var(--white-smoke);
  top: 50%;
  transform: translateY(-50%);
  left: 4px;
  transition: all ease 0.3s;
}

.toggle-wrapper .fee-checkbox {
  cursor: pointer;
  opacity: 0;
  width: 0;
  height: 0;
}

.undercollateralized-notice {
  padding: 10px;
  display: block;
  background: rgba(0, 0, 0, 0.3);
  color: white;
  border-radius: 5px;
}

.undercollateralized-alert {
  background: var(--magnolia) !important;
  margin-top: 0.5em;
  font-size: 1em;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.arb-token-warning {
  margin-top: 7px;
}

.arb-token-warning .alert-box {

  border-radius: 10px;
}

.toggle-wrapper .fee-checkbox:checked + .toggle-wrapper-slider {
  left: 55%;
  background: var(--iris);
}

.toggle-wrapper svg {
  width: 15px;
  margin: 0 5px;
}

.expired-license-filter {
  min-width: 175px;
  max-width: 175px;
}

.protocol-fee-message {
  border-radius: 10px !important;
  margin-top: 1em;
}

.tokens-container::-webkit-scrollbar {
  width: 8px;
}

.tokens-container::webkit-scrollbar-track {
  background: #f9fafb;
}

.tokens-container::-webkit-scrollbar-thumb {
  background: var(--silver);
  border-radius: 2px;
}

@media screen and (max-width: 1180px) {
  .create-pool-wrapper {
    flex-direction: column;
  } 
  .create-pool-right {
    width: 100%;
    margin-bottom: 20px;
  }
  .create-pool-left {
    order: 2;
    margin-right: 0;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 738px) {
  .license-table-wrapper {
    overflow: hidden;
  }
  .token-input-container.token-dropdown {
    flex-direction: column;
  }
  .inner-token-input-container {
    width: 100%;
    margin-right: 0 !important;
    margin-bottom: 5px;
  }
  .toggle-wrapper .toggle-wrapper-label {
    font-size: 13px;
  }
  .lend-ratio-input {
    font-size: 12pt;
  }
  .create-pool-wrapper .increment-selector-wrapper input {
    font-size: 12pt;
  }
}