.selector-container {
  display: flex;
  flex-direction: row;
  height: 45px;
  background: rgba(89, 59, 255, 0.3);
  width: auto;
  min-width: 0px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.selector-container input {
  font-weight: 500;
}

.date-time-container {
  width: auto;
  cursor: pointer;
  width: 180px;
}

.MuiDialogContent-root {
  background: var(--white-smoke);
}

.MuiDialogContent-root div[role="cell"] button.Mui-disabled {
  background: var(--white-smoke);
  color: var(--manatee);
}

.css-7lip4c, .css-118whkv, .css-12ha4i7 {
  background-color: var(--iris) !important;
  border-color: var(--iris) !important;
}

.css-7lip4c span, .css-118whkv span, .css-12ha4i7 span {
  color: white !important;
}

.MuiPickersDay-root.Mui-selected {
  background-color: var(--iris) !important;
}

.css-fhpqww .MuiButtonBase-root.MuiIconButton-root span {
  color: var(--dark-jungle-green) !important;
}

.MuiDialogContent-root span {
  color: var(--dark-jungle-green);
}

.MuiDialogContent-root svg {
  color: var(--dark-jungle-green);
}

.MuiDialogActions-root button {
  color: var(--iris);
}

.PrivatePickersYear-yearButton.Mui-selected {
  background: var(--iris) !important;
}

.PrivatePickersFadeTransitionGroup-root {
  color: var(--dark-jungle-green);
}

span.MuiTypography-root {
  color: var(--dark-jungle-green);
}

.PrivatePickersToolbar-root {
  color: var(--dark-jungle-green);
}

.date-time-container label {
  display: none;
}

.date-time-container input {
  width: 200px;
  font-size: 14px;
  color: var(--iris);
  -webkit-text-fill-color: var(--iris) !important;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  z-index: 2;
}

.date-time-container fieldset {
  border: none;
}

.date-time-container svg {
  position: absolute;
  right: 0.6em;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  width: 20px;
  height: 20px;
}

.selector-button-container {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selector-button-container .selector-number-symbol {
  color: white;
  transform: translate(20px, 0px);
  font-size: 14px;
}

.selector-button-container svg {
  color: white;
  margin: 0 1em;
  z-index: 2;
}

input::-webkit-calendar-picker-indicator {
  transform: translateX(-1em);
  color: white;
  fill: white;
}

.selector-text {
  color: var(--iris);
  font-weight: 500;
  width: 165px;
  height: 100%;
  background: none;
  outline: none;
  border: none;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  z-index: 1;
}

.selector-button-container svg {
  background: var(--iris);
  border-radius: 7px;
  padding: 5px;
}

.selector-arrow {
  margin-left: auto;
  margin-right: 10px;
  color: #fff;
}

.solid-selector {
  background: white;
}

.rate-selector {
  text-align: center;
  width: 165px;
  margin-left: 0;
}

@media screen and (max-width: 738px) {
  .selector-container {
    max-width: 40%;
  }
  .selector-container input{
    text-align: center;
    justify-content: center;
    width: 100% !important;
    font-size: 13px;
  }
}