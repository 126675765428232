.notification-wrapper {
  background: var(--magnolia);
  color: var(--dark-jungle-green);
  border: 1px solid var(--pale-lavender);
  width: 300px;
  height: auto;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(59, 58, 67, 0.2);
  padding: 15px;
  padding-top: 14px;
  position: relative;
  overflow: hidden;
  font-size: 14px;
}

.notification-wrapper .notification-slider {
  height: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: var(--purple-gradient);
}

.notification-wrapper:not(.persist) .notification-slider {
  animation: slide 7s linear;
}

.notification-wrapper .notification-close {
  width: 15px;
  height: 15px;
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 1em;
}

.notification-wrapper .notification-link {
  width: 15px;
  height: 15px;
  cursor: pointer;
  position: absolute;
  top: 1.2em;
  right: 2.5em;
  color: var(--iris);
}

.notification-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notification-top span {
  width: 85%;
}

@keyframes slide {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
