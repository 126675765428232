.set-rollover-wrapper {
  width: 100%;
  height: auto;
  background: white;
  box-shadow: 0px 1px 10px rgba(59, 58, 67, 0.1);
  border-radius: 10px;
  padding: 17px 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.set-rollover-input-wrapper {
  width: 100%;
  height: auto;
  position: relative;
  height: 45px;
  border-radius: 10px;
  border: none;
  background: var(--white-smoke);
  color: var(--dark-jungle-green);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.set-rollover-input-preview {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: var(--davy-grey);
}

.set-rollover-input-preview .loading-skeleton {
  min-width: 95%;
  margin: auto;
}

.set-rollover-input-wrapper svg {
  margin-right: 10px;
}

.set-rollover-wrapper .action-button-container {
  margin: 0;
  width: 100%;
  margin-top: 15px;
}

.set-rollover-wrapper .widget-header {
  color: var(--iris)
}

.set-rollover-wrapper .dropdown-wrapper {
  left: 0;
  top: 120%;
  width: 100%;
}

.no-potential-rollovers {
  color: var(--davy-grey);
  padding: 10px;
}