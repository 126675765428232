.nav-link-container.mobile {
  flex-direction: column;
  position: fixed;
  left: 100%;
  top: 0;
  height: 100%;
  background-image: linear-gradient(to bottom right, rgba(20, 29, 126, 1) 0%, rgba(103, 52, 219, 1) 100%);
  margin-top: 0;
  transition: all ease 0.3s;
  overflow: hidden;
  padding-top: 60px;
  box-shadow: -2px 0px 10px rgba(59, 58, 67, 0.4);
  z-index: 20;
  overflow: hidden;
}

.nav-link-container.mobile.show {
  padding-right: 15px;
  padding-left: 62px;
  display: block;
}

.inner-mobile-menu-button {
  position: absolute;
  color: white;
  top: 15px;
  right: 15px;
  display: block;
}

.nav-link-container.mobile .mobile-nav-link {
  margin-right: 43px;
  display: flex;
  align-items: center;
  color: var(--iris);
  transition: all ease 0.3s;
  margin-right: 2em;
  white-space: nowrap;
  margin-right: 0;
  margin-bottom: 30px;
  color: white;
  font-size: 19px;
}

.mobile-menu-wallet-section {
  width: 100%;
  height: 69px;
  overflow: hidden;
  display: flex;
  margin-bottom: 60px;
}

.jazzicon-wrapper {
  width: 69px;
  height: 69px;
  background: rgba(115, 122, 232, 1);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.mobile-menu-wallet-section .jazzicon {
  width: 61px;
  height: 61px;
}

.mobile-menu-wallet-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  white-space: nowrap;
}

.mobile-menu-wallet-address {
  font-weight: bold;
  font-size: 23px;
}

.mobile-menu-wallet-network {
  display: flex;
  font-size: 19px;
}

.mobile-menu-wallet-network svg {
  width: 15px;
  margin-left: 7px;
  transform: translateY(2px);
}

.mobile-menu-divider {
  width: 85%;
  height: 1px;
  background: white;
  opacity: 0.4;
  margin-bottom: 35px;
}

.mobile-menu-social-icons-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 68%;
  position: absolute;
  bottom: 20px;
  padding: 0 0.5em;
}

.mobile-social-icon svg, .mobile-social-icon img {
  max-width: 18px;
  max-height: 18px;
}

.mobile-social-icon.github {
  transform: translateY(2px);
}



@media screen and (max-width: 1180px) {
  .nav-link-container.mobile {
    width: 390px;
  }
  .nav-link-container.mobile.show {
    left: calc(100% - 390px);
    width: 390px;
  }
}

@media screen and (max-width: 600px) {
  .nav-link-container.mobile {
    width: 100%;
  }
  .nav-link-container.mobile.show {
    left: 0;
    width: 100%;
  }
}