.action-button-container {
  width: 94%;
  margin: auto;
  height: 50px;
  background-color: #FC536C;
  border-radius: 10px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: #8d4ad4;
  background: var(--purple-gradient);
  transition: all ease 0.5s;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  text-transform: capitalize;
}

.action-button-container:hover:not(:disabled) {
  filter: brightness(80%);
}

.action-button-container:active {
  transform: translateY(1px);
}

.action-button-container:disabled {
  background: var(--glitter) !important;
  cursor: not-allowed;
  color: var(--silver);
}
