.alert-box-container{
    position: absolute;
    display: flex;
    flex-direction: column;
    height: auto;
    width: 500px;
    background: var(--white-smoke);
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
}
.alert-box-header{
    color: var(--dark-jungle-green);
    font-size: 19px;
    margin-top: 15px;
}

.alret-box-button{
    color: #fff;
}

.alert-box-notice {
    width: 90%;
    margin-top: 10px;
    margin-bottom: 23px;
}

.hidden{
    display: none;
}

.aler-box-icon{
    color: var(--iris);
    cursor: pointer;
}

.alert-box-body{
    display: flex;
    align-items: center;
    width: 90%;
    justify-content: space-evenly;
    background: var(--pale-lavender);
    border-radius: 50px;
    height: 40px;
    margin-top: 1em;
}

.alert-box-body div {
    color: var(--dark-jungle-green);
}

.alert-box-link-wrapper {
    margin-bottom: 1em;
    width: 30%;
}

.alert-box-link-wrapper button {
    width: 100%;
    margin-top: 1em;
    height: 40px !important;
}

.aler-box-message{
    color: var(--manatee);
}