.featured-pools-wrapper {
  display: flex;
  margin-bottom: 30px;
}

.featured-pool-display {
  width: 173px;
  height: 68px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0px 1px 10px rgba(59, 58, 67, 0.1);
  position: relative;
  cursor: pointer;
  transition: all ease 0.3s;
  margin-right: 10px;
  background: var(--white-smoke);
  color: var(--dark-jungle-green);
  display: flex;
  justify-content: center;
  align-items: center;
}

.featured-pool-display:first-child {
  width: 214px !important;
  background-position: 10%;
  background-size: 100%;
  color: white;
  font-weight: bold;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 1em;
}
.featured-pool-display:last-child {
  margin-right: 0;
}
.featured-pool-display .featured-pool-info {
  display: flex;
  flex-direction: column;
  transform: translateY(2px);
}
.featured-pool-display .featured-pool-name {
  font-size: 14px;
}
.featured-pool-display .featured-pool-count {
  color: var(--manatee);
  font-size: 13px;
  transform: translateY(-2px);
}
.featured-pool-display .featured-pool-image {
  width: 28px;
  height: 28px;
  border-radius: 100%;
  margin-right: 7px;
  transform: translateY(2px);
}

.featured-pool-image-wrapper svg {
  width: 20px;
  margin-right: 10px;
  transform: translateY(2px);
  transition: all ease 0.3s;
}

.featured-gradient {
  position: absolute;
  top: -20%;
  left: -10px;
  width: 120% !important;
  height: auto;
}

.featured-pool-display:hover {
  transform: scale(1.05);
  box-shadow: 0px 0px 10px rgba(59, 58, 67, 0.2);
}

.featured-pool-display:active{
  transform: translateY(1px);
}

.featured-pool-display.selected {
  border: 1px solid var(--pale-lavender);
  box-shadow: 0px 0px 10px #4931cf66;
}

.featured-pool-display.selected svg {
  stroke: var(--iris);
}

.featured-pool-display.selected:not(:first-child) {
  background: var(--magnolia);
}/*# sourceMappingURL=FeaturedPools.css.map */

@media screen and (max-width: 600px) {
  .featured-pool-display {
    padding-left: 6px;
  }
}