.global-message-wrapper {
  transform: translateY(-10px);
  margin-bottom: 10px;
}

.global-message-wrapper .MuiPaper-root {
  border-radius: 7px !important;
}

.global-message-wrapper svg {
  transform: translateY(-1px);
}