.increment-selector-wrapper {
  height: 30px;
  display: flex;
  margin-bottom: 7px;
  position: relative;
}

.increment-selector-wrapper .increment-selector-percent {
  position: absolute;
  right: 50px;
  top: 23%;
  font-size: 11px;
  font-weight: 600;
}

.increment-selector-wrapper .increment-selector-button {
  background: var(--iris);
  border-radius: 7px;
  min-width: 30px;
  min-height: 30px;
  max-width: 30px;
  max-height: 30px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  transition: all ease 0.3s;
  cursor: pointer;
}

.increment-selector-wrapper .increment-selector-button svg {
  width: 13px;
  height: 13px;
}

.increment-selector-wrapper .increment-selector-button:hover {
  transform: scale(1.05);
}

.increment-selector-wrapper .increment-selector-button:active {
  transform: scale(1);
}

.increment-selector-wrapper input {
  flex: 1.5;
  outline: none;
  border: none;
  border-radius: 7px;
  text-align: center;
  margin: 0 7px;
  width: 30px;
  box-shadow: 0px 1px 10px rgba(59, 58, 67, 0.1);
  color: var(--iris);
  font-weight: 600;
}

@media screen and (max-width: 600px) {
  .increment-selector-wrapper input {
    font-size: 12px;
  }
}