.walletModal {
  width: 300px;
  height: auto;
  border-radius: 10px;
  outline: none;
  filter: drop-shadow(0px 3px 13px rgba(14, 9, 47, 0.45));
  border: 1px solid var(--pale-lavender);
  background: var(--magnolia);
  color: var(--dark-jungle-green);
  border: 1px solid var(--pale-lavender);
}

.walletModal .walletProvider {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  margin: 15px 0;
  color: var(--dark-jungle-green);
  font-weight: bold;
  transition: all ease 0.5s;
}

.walletModal .walletProvider span {
  margin-left: 15px;
}

.walletModal .walletProvider:hover {
  filter: brightness(90%);
  background: var(--white-smoke);
  padding-left: 20px;
}
