.license-table-wrapper {
  height: auto;
  min-width: 100%;
  background: white;
  margin-top: 10px;
  border-radius: 10px;
  box-shadow: 0px 1px 10px rgba(59, 58, 67, 0.08);
  padding: 18px 37px;
}

.license-table-wrapper .license-table-header {
  font-size: 16px;
  margin-bottom: 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.license-table-wrapper .license-table-header .license-table-header-text {
  display: flex;
  align-items: center;
  font-weight: 500;
}

.license-table-wrapper .license-table-header .license-table-header-text:last-child {
  color: var(--iris);
  font-size: 13px;
  font-weight: normal;
  cursor: pointer;
}

.license-table-wrapper .license-table-header svg {
  width: 15px;
  color: var(--iris);
  margin-left: 5px;
}

.license-table-wrapper a {
  text-decoration: underline;
}

.license-rows-header-container {
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  margin-bottom: 11px;
  font-size: 13px;
}

.license-rows-header-container .license-column-header {
  font-size: 13px;
  cursor: pointer;
  color: var(--manatee);
  white-space: nowrap;
  user-select: none;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  text-align: left;
  max-width: 100px;
  min-width: 100px;
}

.license-rows-header-container .license-column-header:nth-child(3) {
  transform: translate(-10px);
}

.license-rows-header-container .license-column-header:nth-child(4) {
  transform: translate(-5px);
}

.license-rows-header-container .license-column-header svg {
  width: 10px;
  height: 10px;
  fill: var(--manatee);
}

.license-row-wrapper {
  width: 100%;
  height: 45px;
  display: flex;
  position: relative;
  border: 1px solid white;
  border-top: 1px solid var(--white-smoke);
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  transition: all ease 0.2s;
  border-radius: 10px;
  outline: none;
  font-size: 14px;
}

.license-row-wrapper.selected {
  background: var(--magnolia);
  border: 1px solid var(--pale-lavender);
}

.license-row-wrapper .license-row-column {
  display: block;
  max-width: 100px;
  min-width: 100px;
  white-space: nowrap;
}

.license-row-wrapper .license-row-column:nth-child(1) {
  transform: translateX(8px);
}

.license-row-wrapper .license-row-column:nth-child(3) {
  transform: translateX(-10px);
}

.license-row-wrapper .pool-count-column {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.license-row-wrapper .pool-count-column span {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 13px;
  background: rgba(89, 59, 255, 0.3);
  color: var(--iris);
  font-size: 11px;
  height: 22px;
  border-radius: 7px;
  max-width: 64px;
  min-width: 64px;
}

.license-row-wrapper:hover {
  background: var(--white-smoke);
}

@media screen and (max-width: 600px) {
  .license-table-wrapper {
    padding-left: 25px; 
    padding-right: 25px;
  }
  .license-rows-header-container .pool-count-header {
    text-align: right;
  }
  .license-row-wrapper .pool-count-column {
    justify-content: flex-end;
    padding-right: 10px;
  }
  .license-row-wrapper .pool-count-column span {
    min-width: 10px;
    max-width: 10px;
  }
  .license-rows-header-container .license-column-header:nth-child(2) {
    transform: translateX(20px);
  }
  .license-row-wrapper .license-row-column:nth-child(1) {
    transform: translateX(8px);
  }
  .license-row-wrapper .license-row-column:nth-child(2) {
    transform: translateX(20px);
  }
  .license-row-wrapper .license-row-column:nth-child(3) {
    transform: translateX(0px);
  }
}