.verification-loading-wrapper {
  width: 80%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.verification-loading-wrapper img {
  width: 100%;
  height: 100%;
  filter: drop-shadow(0px 0px 10px rgba(33, 33, 33, 0.5));
  margin-bottom: 5px;
}

.verification-loading-wrapper span {
  font-family: "Inter", sans-serif;
  font-size: 20px;
}

.blocked-ip-message {
  display: flex;
  flex-direction: column;
  width: 600px;
}