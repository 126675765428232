.token-selector-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 45px;
  background-color: var(--white-smoke);
  border-radius: 10px;
  align-items: center;
  justify-content: space-between;
}

.token-selector-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 45px;
  background-color: var(--white-smoke);
  border-radius: 10px;
  align-items: center;
  justify-content: space-between;
  transition: all ease 0.3s;
}

.token-selector-container .MuiSkeleton-root {
  min-width: 80px;
  max-height: 15px;
  transform: translateX(-10px);
}

.token-selector-container.select:hover {
  filter: brightness(90%);
}

.token-selector-container.select svg {
  color: var(--iris);
  transform: translateX(-5px);
  cursor: pointer;
  width: 50px;
  height: 24px;
}

.token-selector-container.select input {
  cursor: pointer;
  color: #9ea1ac;
}

.token-selector-separator {
  height: 45px;
  width: 1px;
  background-color: #DEE1ED;
  position: absolute;
  right: 0;
}

.token-balance {
  color: #9EA1AC;
  justify-self: flex-end;
  margin-right: 10px;
  font-size: 0.9em;
  white-space: nowrap;
  cursor: pointer;
  font-size: 13px;
}

.token-symbol {
  color: var(--dark-jungle-green);
  font-weight: 500;
}

.token-selector-container.select input {
  transform: translateX(-10px);
  text-align: right;
}

.token-drop-down .token-logo {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.token-amount-entry {
  background-color: transparent;
  border: none;
  height: 45px;
  padding-left: 0.5em;
  outline: none;
  color: var(--dark-jungle-green);
  font-size: 14px;
  width: inherit;
  display: flex;
  align-items: center;
}

.token-selector-container.select input {
  cursor: pointer;
  color: #9ea1ac;
}

.token-selector-separator {
  height: 45px;
  width: 1px;
  background-color: #DEE1ED;
  position: absolute;
  right: 0;
}

.token-balance {
  color: #9EA1AC;
  justify-self: flex-end;
  margin-right: 10px;
  font-size: 0.9em;
  white-space: nowrap;
  cursor: pointer;
  font-size: 13px;
}

.token-logo {
  width: 35px;
  height: 35px;
}

.token-symbol {
  color: var(--dark-jungle-green);
  font-weight: 500;
}

.token-drop-down {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 0 10px;
  align-items: center;
}