.potential-rollover {
  width: 100%;
  height: 40px;
  cursor: pointer;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.potential-rollover span {
  font-size: 14px;
}

.potential-rollover:hover {
  background: var(--white-smoke);
}

.potential-rollovers-wrapper {
  max-height: 500px;
}

.potential-rollover .icon-pair {
  transform: translateY(3px);
}

.potential-rollover .loading-skeleton {
  min-width: 85px;
}