.my-pools-wrapper {
  width: 100%;
  height: 100%;
  padding-bottom: 5px;
  display: flex;
}

.my-pools-wrapper .my-pools-left-section {
  max-width: 756px;
  flex: 1.5;
  margin-right: 15px;
}

.my-pools-wrapper .my-pools-right-section {
  flex: 1;
}

.my-pools-wrapper .my-pools-header {
  margin-top: 31px;
  margin-bottom: 10px;
  display: flex;
}

.my-pools-wrapper .loader {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.my-pools-graph-section {
  height: 219px;
  display: flex;
}

.my-pools-graph-section .my-pools-left-graph {
  flex: 1.5;
  margin-right: 11px;
  height: 100%;
  min-width: 558px;
  min-height: 219px;
}

.my-pools-graph-section .my-pools-right-graph {
  flex: 1;
  max-width: 297px; 
}

.my-pools-right-graph .no-data-message {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80px;
  text-align: center;
  font-size: 12px;
  transform: translateX(-50%); 
  color: var(--auro-metal-saurus);
  z-index: 1;
}

.my-pools-graph-section .graph-container {
  height: 100%;
  border-radius: 10px;
  position: relative;
  box-shadow: 0px 1px 10px rgba(59, 58, 67, 0.1);
  padding: 15px;
  background: white;
  font-size: 10px;
  min-height: 219px;
}

.mode-switch-container {
  height: 35px;
  display: flex;
  margin-right: 10px;
  background: white;
  border-radius: 10px;
  width: 160px;
  padding: 0 10px;
  position: relative;
  overflow: hidden;
}

.mode-switch-container .mode-switch-slider {
  position: absolute;
  background: var(--purple-gradient);
  width: 49px;
  left: 0;
  height: 100%;
  border-radius: 10px;
  z-index: 1;
  transition: all ease 0.3s;
}

.mode-switch-container .mode-switch-slider.right {
  width: 100px;
  left: 32%;
}

.mode-switch-container span {
  display: flex;
  align-items: center;
  z-index: 2;
  font-size: 13px;
  color: var(--manatee);
  cursor: pointer;
  transition: all ease 0.3s;
}

.mode-switch-container span.active {
  color: white;
}

.mode-switch-container span:last-child {
  margin-left: 20px;
}

.my-pools-wrapper .pool-rows-container {
  width: 100%;
  background: white;
  overflow: visible;
  padding: 1em;
  border-radius: 10px;
  box-shadow: 0px 1px 10px rgba(59, 58, 67, 0.08);
}

.pool-row-header {
  width: 100%;
  display: flex;
  margin-bottom: 0.5em;
  justify-content: space-between;
  font-size: 14px;
  flex: 1;
}

.pool-row-header-entry {
  font-size: 16px;
  color: var(--almost-white);
  text-align: center;
  width: 120px;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}

.pool-row-header-entry.sortable {
  cursor: pointer;
}

.pool-row-header-entry.sortable svg {
  margin-left: 3px;
  max-width: 15px;
  max-height: 15px;
  min-width: 15px;
  min-height: 15px;
}

.pool-row-header-entry.sortable.ascending svg {
  transform: rotate(180deg);
}

.pool-rows-wrapper {
  width: auto;
  overflow-y: auto;
  flex: 1;
}

.pool-rows-wrapper .no-pools-notice {
  position: relative;
  display: flex;
  width: 100%;
  height: 60%;
  justify-content: center;
  align-items: center;
}

.my-pools-wrapper .tab-container {
  background: none;
  width: 100%;
}

.my-pools-wrapper .tab-container .tab-slider {
  width: 50%;
}

.my-pools-wrapper .tab-slider.lent {
  transform: translateX(100%);
}

.my-pools-wrapper .tab-slider.repay, .my-pools-wrapper .tab-slider.add {
  left: 0;
}

.my-pools-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 35px;
}

.balance-manager-wrapper, .my-pools-collect-wrapper{
  width: 100%;
  height: auto;
  background: white;
  box-shadow: 0px 1px 10px rgba(59, 58, 67, 0.1);
  border-radius: 10px;
  padding: 12px 17px;
  display: flex;
  flex-direction: column;
}

.balance-manager-wrapper .token-selector-container {
  margin-top: 15px;
}

.balance-manager-wrapper .toggle-wrapper {
  margin-top: 8px;
}

.my-pools-collect-wrapper .MuiSkeleton-root {
  display: none;
}

.my-pools-graph-title {
  font-size: 16px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
}

.pool-stats-button {
  background: rgba(89, 59, 255, 0.3);
  box-shadow: 0px 1px 10px rgba(59, 58, 67, 0.2);
  border-radius: 7px;
  color: var(--iris);
  cursor: pointer;
  transition: all ease 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1px 7px;
}

.pool-stats-button:hover {
  transform: scale(1.05);
}

.pool-stats-button:active {
  transform: scale(1);
}

.balance-manager-wrapper .action-button-container, .my-pools-collect-wrapper .action-button-container {
  height: 45px;
  width: 50%;
  margin: 0;
  margin-top: 15px;
}

.my-pools-small-widgets-wrapper {
  display: flex;
  width: 100%;
  margin-top: 15px;
  justify-content: space-between;
}

.my-pools-small-widgets-wrapper .action-button-container {
  width: 100%;
  box-shadow: 0px 1px 10px rgba(59, 58, 67, 0.1);
}

.my-pools-small-widgets-wrapper .small-widget {
  width: 235px;
  padding: 23px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0px 1px 10px rgba(59, 58, 67, 0.1);
}

.my-pools-small-widgets-wrapper .small-widget .widget-icon {
  transition: all ease 0.3s;
  margin-bottom: 25px;
  width: 38px;
  height: 38px;
  min-width: 38px;
  min-height: 38px;
}

.my-pools-small-widgets-wrapper .small-widget .widget-icon:hover {
  transform: rotate(180deg);
}

.my-pools-small-widgets-wrapper .small-widget .action-button-container {
  min-height: 30px;
  max-height: 30px;
  font-size: 12px;
  border-radius: 7px;
}

.my-pools-small-widgets-wrapper .widget-header {
  font-weight: 600;
  font-size: 14px;
}

.my-pools-small-widgets-wrapper .widget-description {
  font-size: 12px;
  margin: 7px 0;
}

.my-pools-small-widgets-wrapper .update-fee-rate-wrapper {
  background: var(--pale-lavender);
  color: var(--iris);
  margin-right: 15px;
}

.my-pools-small-widgets-wrapper .pause-borrowing-wrapper {
  background: var(--seashell);
  color: var(--cherry);
  padding-bottom: 10px !important;
  height: 265px;
}

.my-pools-small-widgets-wrapper .pause-borrowing-wrapper .action-button-container {
  background: var(--red-gradient);
}

.my-pools-collect-wrapper .collect-inner-wrapper {
  display: flex;
}
.my-pools-collect-wrapper .collect-inner-wrapper .token-selector-container {
  margin-top: 5px;
}
.my-pools-collect-wrapper .collect-inner-wrapper .token-selector-container:first-child {
  margin-right: 15px;
}
.my-pools-collect-wrapper .action-button-container {
  width: 100%;
}

.my-pools-collect-wrapper .collect-message {
  border-radius: 10px;
  margin-top: 13px;
}

.my-pools-left-graph .legend-item-3 {
  margin-right: 0;
}

.my-pools-graph-section .my-pools-right-graph .recharts-legend-wrapper {
  left: 68% !important;
  top: 36% !important;
  width: 100%;
}

.my-pools-graph-section .recharts-legend-item {
  margin-bottom: 4px;
}

.pie-chart-text {
  font-size: 16px;
  font-weight: 600;
  transform: translateY(-4px);
}

.my-pools-utilization-text {
  position: absolute;
  top: 62%;
  left: 50%;
  transform: translate(calc(-50% + 2px), -3px);
  color: var(--iris);
  font-size: 10px;
}

.my-pools-right-section.modal {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100vw;
  max-width: 100vw;
  min-height: 100vh;
  background: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;
  overflow-y: auto;
  padding: 0px 5px;
  padding-top: 40px;
}

.my-pools-close-modal {
  color: var(--iris);
  z-index: 10;
  background-color: white;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  box-shadow: 0px 1px 10px rgba(59, 58, 67, 0.1);
  padding: 3px;
}

.my-pools-close-modal svg {
  width: 20px;
  height: 20px;
}

@media screen and (max-width: 1180px) {
  .my-pools-header {
    margin-top: 0 !important;
  }
  .my-pools-left-section {
    margin-right: 0 !important;
  }
  .balance-manager-wrapper, .my-pools-collect-wrapper {
    flex-direction: column;
    max-width: 326px;
  }

}


@media screen and (max-width: 600px) {
  .my-pools-right-section {
    flex-direction: column;
  }
  .my-pools-left-section {
    max-width: 100% !important;
  }
  .my-pools-left-graph{
    margin-right: 0 !important;
    margin-bottom: 5px !important;
    width: 100%;
    min-width: 0 !important;
    max-width: 100% !important;
  }
  .my-pools-left-graph .pool-simulation-wrapper {
    margin-bottom: 0;
  }
  .my-pools-right-graph {
    width: 100%; 
    max-width: 100% !important; 
  }
  .my-pools-graph-section {
    flex-direction: column;
    height: auto;
    max-width: 100% !important;
  }
  .my-pools-left-section {
    display: flex;
    flex-direction: column;
  }
  .my-pools-graph-section {
    max-height: 500px;
    order: 3;
  }
  .my-pools-left-section .pool-rows-container {
    margin-bottom: 20px;
  }
  .my-pools-small-widgets-inner-wrapper {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
  }
  .my-pools-small-widgets-wrapper {
    display: flex;
    flex-direction: column;
  }
  .mode-switch-container {
    min-width: 125px
  }
  .mode-switch-container span {
    font-size: 12px;  
  }
  .mode-switch-container span:last-child {
    margin-left: 18%;
  }
  .my-pools-small-widgets-wrapper .pause-borrowing-wrapper {
    min-height: 300px !important;
  }
  .balance-manager-wrapper, .my-pools-collect-wrapper {
    flex-direction: column;
    max-width: 100%;
  }
  .my-pools-wrapper .lending-pool-close-modal-wrapper {
    display: none;
  }
}