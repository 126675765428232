.tab-container {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  border-radius: 10px 10px 0 0;
  background: none;
  z-index: 3;
}

.tab-container.no-padding {
  padding: 0;
}

.tab-item {
  flex: 1;
  color: var(--dark-jungle-green);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 19px;
  cursor: pointer;
  margin-bottom: 5px;
}

.tab-rails {
  height: 5px;
  width: 100%;
  border-radius: 3px;
  background-color: var(--gainsboro);
  transition: all ease 0.3s;
  display: flex;
  position: relative;
  overflow: hidden;
}

.tab-slider {
  height: 5px;
  width: 30%;
  border-radius: 3px;
  background: var(--purple-gradient);
  transition: all ease 0.3s;
  position: absolute;
}

.tab-slider.borrow, .tab-slider.add, .tab-slider.repay {
  left: 0%;
}

.tab-slider.rollover, .tab-slider.withdraw {
  left: 50%;
}

.tab-slider.fill {
  width: 100%;
}
